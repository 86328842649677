<template>
        <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
            cancelText="取消" centered okText="开启" width="400px" :zIndex='4200'
            @ok="handleOk" @cancel="handleCancel">
            <a-form layout="vertical">

                <a-form-item  label="请选择事件">
                    <a-select style="width: 100%" v-model='event_id' placeholder="请选择事件">
                        <a-select-option v-for="(d, index) of eventList" :key="index" :value="d['event_id']">{{ d['event_name'] }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item  label="转移方式">
                    <a-radio-group v-model='process_all'>
                        <a-radio :value="0">当前选中</a-radio>
                        <a-radio :value="1">全量筛选</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </a-modal>
</template>

<script>
    export default {
        name:'startEvent',
        inject: ['parent'],
        props:['item'],
        data() {
            return {
                visible:false,
                ModalTitle: '事件跟踪',
                confirmLoading:false,
                process_all:0,
                eventList:[],
                event_id:'',
            }
        },
        created () {
            this.visible = true
            this.getEventList()
        },
        beforeDestroy () {
            this.visible = false
        },
        methods: {
            async getEventList(){
                await this.$store.dispatch('cFollowEventAction', {})
                .then(res=>{
                    this.eventList = res.data
                })
            },
            async handleOk() {
                if(!this.event_id){
                    this.$message.warning('请选择事件！！')
                    return false
                }
                this.confirmLoading=true
                let task_id = this.item.map(item=>item.task_id)
                await this.$store.dispatch('cCustomerStartEventAction',{task_id,event_id:this.event_id,process_all:this.process_all})
                .then(res=>{
                    this.$message.success('操作成功!')
                    this.visible = false
                    this.parent.hideStartEvent(1)
                })
                .catch(err=>{
                    this.confirmLoading=false
                })
                
            },
            handleCancel(){
                this.parent.hideStartEvent(0)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>